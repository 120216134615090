import React from 'react'
import './Feature.scss'

function Feature(props) {
    return (
        <div className='feature-container'>
            <div className='feature-img'>{props.icon}</div>
            <div className='feature-label-area'>
                <span className='feature-label'>{props.label}</span>
            </div>
        </div>
    )
}

export default Feature