import React from 'react'
import './Card.scss'
function Card(props) {
    return (
        <div className='card-container'>

            <div className={`card-top ${props.color}`}>
                <div className='card-icon'>
                    {props.icon}
                </div>
            </div>

            <div className='card-infos'>
                <span className='card-name'>{props.name}</span>
                <span className='card-descript'>{props.descript}</span>
            </div>
        </div>
    )
}

export default Card