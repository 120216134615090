// src/components/Menu/Menu.js
import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Menu.scss';
import logo from '../../assets/logo.png';

const Menu = ({ scrollToSection, heroRef, historyRef, explainRef, contatarRef }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <img src={logo} alt="Logo" className="logo" />

      <div className="menu-icon" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
        <li className="menu-item" onClick={() => scrollToSection(heroRef)}>
          <a href="#home" className="menu-link">Home</a>
        </li>
        <li className="menu-item" onClick={() => scrollToSection(historyRef)}>
          <a href="#about" className="menu-link">Sobre</a>
        </li>
        <li className="menu-item" onClick={() => scrollToSection(explainRef)}>
          <a href="#dealers" className="menu-link">Revendedores</a>
        </li>
        <li className="menu-item" onClick={() => scrollToSection(contatarRef)}>
          <a href="#contact" className="menu-link">Contato</a>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;