// src/components/Explain.js
import React from 'react';
import '../styles/explain.scss';

function Explain() {
  return (
    <div className='explain-container'>
      <div className='card-explain card1'>
        <span className='card-explain-title'>O que é a Las Coxinhas Express?</span>
        <span className='card-explain-sub'>Salgados prontos para atender você em todos os tipos de ocasiões! Revendedores, festas, eventos, degustação e muito mais!</span>
        <span className='card-explain-sub'>Experimente a praticidade e o sabor de nossos salgados que chegam até você fresquinhos e crocantes, prontos para qualquer ocasião especial.</span>
      </div>

      <div className='card-explain card2'>
        <div className='cape-black'></div>
      </div>

      <div className='card-explain card3'>
        <div className='cape-black'>
          <span className='card-explain-title'>Tem dúvidas?</span>
          <span className='card-explain-sub'>Nunca trabalhou como revendedor e gostaria de iniciar? Sem problemas!</span>
          <span className='card-explain-sub'>Mande uma mensagem e iremos ajudar você a iniciar!</span>
          <a href="https://wa.me/5555551999832?text=Ol%C3%A1%2C+tudo+bem%3F+Eu+gostaria+de+come%C3%A7ar+como+um+novo+revendedor+da+Las+Coxinhas+Express%21" className="btn-explain">Quero começar</a>
        </div>
      </div>

      <div className='card-explain card4'>
        <div className='cape-black right'>
          <span className='card-explain-title'>Revendedor</span>
          <span className='card-explain-sub'>Já é um revendedor e quer conversar conosco sobre possibilidades? Contate-já!</span>
          <a href="https://wa.me/5555551999832?text=Oi%21+J%C3%A1+sou+revendedor+ou+tenho+um+neg%C3%B3cio+e+gostaria+de+explorar+novas+possibilidades+com+a+Las+Coxinhas+Express%21" className="btn-explain">Contatar</a>
        </div>
      </div>
    </div>
  );
}

export default Explain;
