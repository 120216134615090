import React from 'react';
import '../styles/entrega.scss';
import entrega from '../assets/entrega.png';

function Entrega() {
    return (
        <div className='entrega-container'>
            <div className='entrega-infos'>
                <span className='entrega-title'>
                    Deixa que a gente entrega!
                </span>
                <span className='entrega-text'>Entregamos na região metropolitana de Campinas para você, nosso revendedor, não ter que se preocupar em retirar ou buscar nenhum produto!</span>
            </div>
            <div className='entrega-img'>
                <img src={entrega} alt="Entrega" className='img-entrega' />
            </div>
        </div>
    );
}

export default Entrega;
