// src/components/History.js
import React from 'react';
import '../styles/history.scss';
import Feature from '../components/Feature/Feature';
import { FaPeopleCarry, FaEye } from "react-icons/fa";
import { GiDiploma, GiChefToque } from "react-icons/gi";
import chef from '../assets/chef.png';

function History() {
  return (
    <div className='history-container'>
      <div className='history-left'>
        <img src={chef} className='chef-image' alt="Chef" />
      </div>
      <div className='history-right'>
        <h2>O GOSTO DA HISTÓRIA</h2>
        <h3>O SABOR DA AMIZADE</h3>
        <span>
          A Las Coxinhas Express nasceu em 2009 da parceria entre um padeiro apaixonado por massas e uma cozinheira de mão cheia. O que começou como um trabalho em uma pequena padaria, logo se tornou uma oportunidade saborosa.
        </span>
        <span>
          A ideia surgiu de uma conversa informal: "Você poderia fazer salgados para entregar em bares, lanchonetes e buffets?". No dia seguinte, a cozinheira levou o padeiro até o bar de uma amiga e, ali mesmo, a Las Coxinhas Express começou sua jornada, inicialmente com o nome de Nick Salgados.
        </span>
        <span>
          Com o passar do tempo, o nome mudou, mas a paixão por oferecer salgados deliciosos e de qualidade só aumentou. A Las Coxinhas Express cresceu, mas mantém viva a essência daquela parceria inicial: <span className='bold'>oferecer sabor e praticidade para seus clientes, em cada mordida.</span>
        </span>
        <span className='span-features'>Nossos pilares:</span>
        <div className='features-row'>
          <Feature label="Para Todos" icon={<FaPeopleCarry />} />
          <Feature label="Ética e Organização" icon={<FaEye />} />
          <Feature label="Responsabilidade" icon={<GiDiploma />} />
          <Feature label="Sabor e Tradição" icon={<GiChefToque />} />
        </div>
        <span className='phrase-italic'>"Levar nossos salgadinhos para as pessoas é levar um gosto bom de viver para cada boca!"</span>
      </div>
    </div>
  );
}

export default History;
