import React, { useState } from 'react';
import '../styles/contatar.scss';
import ContactModal from '../components/ContactModal/ContactModal';

function Contatar() {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <div className='contatar-container'>
            <span className='contatar-title'>Gostou do que viu?</span>
            <span className='contatar-title'>Seja um revendedor!</span>
            <span className='contatar-text'>Quando a decisão é certa, não tem porque esperar! Junte-se a nós para levar o verdadeiro sabor para cada pessoa!</span>
            <button className='contatar-btn' onClick={openModal}>Contatos</button>
            <ContactModal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    );
}

export default Contatar;
