import React from 'react';
import './Banner.scss';
import { MdOutlineEmail, MdPhone, MdOutlineWhatsapp, MdFacebook } from "react-icons/md";
import { AiOutlineInstagram } from "react-icons/ai";

function openSocialMedia(platform) {
    let url;

    switch(platform.toLowerCase()) {
        case 'instagram':
            url = 'https://www.instagram.com/lascoxinhasexpress/';
            break;
        case 'facebook':
            url = 'https://www.facebook.com';
            break;
        case 'whatsapp':
            url = 'https://wa.me/5519998329453?text=Ol%C3%A1%2C+tudo+bem%3F+Gostaria+de+conversar+sobre+o+Las+Coxinha+Express%21';
            break;
        default:
            console.error('Plataforma não suportada');
            return;
    }

    window.open(url, '_blank');
}

function Banner() {
    return (
        <div className='banner-container'>
            <div className='banner-contact'>
                <div className='banner-group'>
                    <MdOutlineEmail />
                    <span>lascoxinhasexpress@gmail.com</span>
                </div>
                <div className='banner-group'>
                    <MdPhone />
                    <span>(19) 3228-0397</span>
                </div>
            </div>
            <div className='banner-social'>
                <MdOutlineWhatsapp className='whatsapp-color social' onClick={() => {openSocialMedia("whatsapp")}}/>
                <MdFacebook className='facebook-color social' onClick={() => {openSocialMedia("facebook")}}/>
                <AiOutlineInstagram className='instagram-color social' onClick={() => {openSocialMedia("instagram")}}/>
            </div>
        </div>
    )
}

export default Banner;