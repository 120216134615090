import React from 'react';
import '../styles/gallery.scss';

import g1 from '../assets/galery/img1.jpg';
import g2 from '../assets/galery/img2.jpg';
import g3 from '../assets/galery/img3.jpg';
import g4 from '../assets/galery/img4.jpg';
import g5 from '../assets/galery/img5.jpg';
import g6 from '../assets/galery/img6.jpg';
import g7 from '../assets/galery/img7.jpg';
import g8 from '../assets/galery/img8.jpeg';
import g9 from '../assets/galery/img9.jpeg';
import g10 from '../assets/galery/img10.jpeg';
import g11 from '../assets/galery/img11.jpeg';
import g12 from '../assets/galery/img12.jpeg';



function Gallery() {
    return (
        <div className="gallery-container">
            <h2>Nossa Galeria</h2>
            <div className="gallery-grid">
                <div className="gallery-item">
                    <img src={g1} alt="Coxinhas" />
                </div>
                <div className="gallery-item">
                    <img src={g2} alt="Salgados variados" />
                </div>
                <div className="gallery-item">
                    <img src={g3} alt="Salgadinhos" />
                </div>
                <div className="gallery-item">
                    <img src={g4} alt="Coxinha com bebida" />
                </div>
                <div className="gallery-item">
                    <img src={g5} alt="Coxinhas" />
                </div>
                <div className="gallery-item">
                    <img src={g6} alt="Salgados variados" />
                </div>
                <div className="gallery-item">
                    <img src={g8} alt="Coxinhas" />
                </div>
                <div className="gallery-item">
                    <img src={g9} alt="Salgados variados" />
                </div>
                <div className="gallery-item">
                    <img src={g10} alt="Salgadinhos" />
                </div>
                <div className="gallery-item">
                    <img src={g11} alt="Coxinha com bebida" />
                </div>
                <div className="gallery-item">
                    <img src={g12} alt="Coxinhas" />
                </div>
                <div className="gallery-item">
                    <img src={g7} alt="Salgados variados" />
                </div>
            </div>
        </div>
    );
}

export default Gallery;
