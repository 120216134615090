// src/components/Modal/ContactModal.js
import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com'; // Importando emailjs
import './ContactModal.scss';

function ContactModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Defina os parâmetros para enviar o email usando EmailJS
    const serviceID = 'service_fu6gt4g';
    const templateID = 'template_dtjq59w';
    const publicKey = '2-3GioqxSm3oBwMDR';

    emailjs.send(serviceID, templateID, formData, publicKey)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        onClose(); // Fechar modal após o envio
      }, (err) => {
        console.log('FAILED...', err);
      });
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest('.contact-modal') && isOpen) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className='modal-overlay'>
      <div className='contact-modal'>
        <h2>Deixe seu contato</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Nome:
            <input
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Telefone:
            <input
              type='tel'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Mensagem:
            <textarea
              name='message'
              value={formData.message}
              onChange={handleChange}
              rows={4}
              required
            />
          </label>
          <button type='submit'>Enviar</button>
        </form>
      </div>
    </div>
  );
}

export default ContactModal;
