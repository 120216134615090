import React from 'react'
import '../styles/footer.scss'

function Footer() {
    return (
        <div className='footer-container'>
            Desenvolvido por <a href='https://portfolio-jovi-a8jn76wqx-archesz.vercel.app'> @Archs</a>.
        </div>
    )
}

export default Footer