import React, { useRef } from 'react';

import Menu from './components/Menu/Menu';
import Banner from './components/Banner/Banner';
import Hero from './pages/Hero';
import History from './pages/History';
import Explain from './pages/Explain';
import About from './pages/About';
import Entrega from './pages/Entrega';
import Gallery from './pages/Gallery';
import Comentarios from './pages/Comentarios';
import Contatar from './pages/Contatar';
import Footer from './pages/Footer';

import './styles/global.scss';

function App() {
  const heroRef = useRef(null);
  const historyRef = useRef(null);
  const explainRef = useRef(null);
  const contatarRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container">
      <Menu scrollToSection={scrollToSection} heroRef={heroRef} historyRef={historyRef} explainRef={explainRef} contatarRef={contatarRef} />

      <div ref={heroRef}>
        <Hero />
      </div>
      <div ref={historyRef}>
        <History />
      </div>
      <div ref={explainRef}>
        <Explain />
      </div>
      <About />
      <Gallery />

      <Entrega />
      <div ref={contatarRef}>
        <Contatar />
      </div>
      <Footer />
    </div>
  );
}

export default App;