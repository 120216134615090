// src/components/About.js
import React from 'react';
import Card from '../components/Card/Card';
import { GiPotato } from "react-icons/gi";
import { MdOutlineAddBusiness } from "react-icons/md";
import { MdWeb } from "react-icons/md";

import '../styles/about.scss';

function About() {
  return (
    <div className='about-container'>
      <div className='about-top'>
          <h2>Sobre Nós</h2>
          <span>Além da nossa história, a Las Coxinhas Express conta com tradições e pilares que são nossos guias na cozinha até chegar em você!</span>
          <span>Ficou curioso em como somos diferentes e como somos a escolha certa pra você?</span>
          <span>Conheça um pouco sobre nós abaixo:</span>
      </div>
      <div className='about-bot'>
        <div className='row'>
          <Card color="red" name="Massa de Batata" descript="Todos nossos salgados são produzidos com massa de batata" icon={<GiPotato/>}/>
          <Card color="yellow" name="Revendedores" descript="Abasteça seu negócio com o sabor irresistível das Coxinhas Express! Entregas especiais para eventos e estabelecimentos." icon={<MdOutlineAddBusiness/>}/>
          <Card color="black" name="Ativo nas Redes" descript="Estamos super ativos nas redes sociais! Nos siga para não perder as promoções!" icon={<MdWeb/>}/>
        </div>
        <a href="https://wa.me/5555551999832?text=Ol%C3%A1%2C+tudo+bem%3F+Eu+gostaria+de+come%C3%A7ar+como+um+novo+revendedor+da+Las+Coxinhas+Express%21" className='btn-about'>Seja um revendedor!</a>
      </div>
    </div>
  );
}

export default About;
