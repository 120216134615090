// src/components/Hero.js
import React, { useState } from 'react';
import '../styles/hero.scss';
import heroImage from '../assets/heroImage.png';
import ContactModal from '../components/ContactModal/ContactModal';

function Hero() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className='hero'>
      <div className='hero-left'>
        <h1 className='white'>SABOR QUE CONQUISTA DESDE A <span className='yellow-word'>PRIMEIRA</span> MORDIDA!</h1>
        <h3 className='white'>Coxinhas crocantes, risoles recheados e muito mais. Peça online e receba tudo pronto para saborear.</h3>

        <div className='hero-row'>
          <button className='btn btn-contact' onClick={openModal}>
            Contato
          </button>
        </div>
      </div>
      <div className='hero-right'>
        <img src={heroImage} className="hero-image" alt="Hero" />
      </div>

      <ContactModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default Hero;
